<template>
  <div class="aside aside-left d-flex flex-column" id="kt_aside" ref="kt_aside">
    <!--begin::Brand-->
    <div
      class="aside-brand d-flex flex-column align-items-center flex-column-auto pt-5 pt-lg-18 pb-10"
    >
      <!--begin::Logo-->
      <div
        class="btn p-0 symbol symbol-60 symbol-light-primary"
        id="kt_quick_user_toggle"
      >
        <!-- <router-link to="/">
          <div class="symbol-label" style="background:transparent; opacity:70%">
            <inline-svg
              alt="Logo"
              src="/media/logo/logo.svg"
              class="h-75 align-self-end"
            />
          </div>
        </router-link> -->
      </div>
      <!--end::Logo-->
    </div>
    <!--end::Brand-->
    <!--begin::Nav Wrapper-->
    <div
      class="aside-nav d-flex flex-column align-items-center flex-column-fluid pb-10 mt-20"
    >
      <!--begin::Nav-->
      <ul class="nav flex-column">
        <!--begin::Item-->
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <li v-bind="attrs" v-on="on" class="nav-item mb-2">
              <router-link to="/dashboard"
                class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
              >
                <span class="svg-icon svg-icon-xxl">
                  <inline-svg src="/media/svg/icons/Layout/Layout-4-blocks.svg" />
                </span>
              </router-link>
            </li>
          </template>
          <span>Dashboard</span>
        </v-tooltip>
        <!--end::Item-->

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <li v-bind="attrs" v-on="on" class="nav-item mb-2">
              <router-link to="/build/new"
                class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
                data-toggle="tab"
                data-target="#kt_aside_tab_3"
                role="tab"
              >
                <span class="svg-icon svg-icon-xxl">
                  <inline-svg src="/media/svg/icons/General/Shield-check.svg" />
                </span>
              </router-link>
            </li>
          </template>
          <span>Builder</span>
        </v-tooltip>

        <KTQuickStats></KTQuickStats>
      </ul>
      <!--end::Nav-->
    </div>
    <!--end::Nav Wrapper-->

    <!--begin::Footer-->
    <div
      class="aside-footer d-flex flex-column align-items-center flex-column-auto py-8"
    >
      <!--begin::Item-->
      <!-- <KTQuickNotifications></KTQuickNotifications>
      <KTQuickPanel></KTQuickPanel> -->

    </div>
    <!--end::Footer-->
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
// import KTQuickNotifications from "@/view/layout/extras/offcanvas/QuickNotifications.vue";
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import KTQuickStats from "@/view/layout/extras/offcanvas/QuickStats.vue";

export default {
  name: "Aside",
  components: {
    // KTQuickNotifications,
    // KTQuickPanel,
    KTQuickStats
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);
    });
  }
};
</script>
