<template>
  <div class="topbar d-flex align-items-center">
    <!-- <router-link to="/build/new" class="btn font-weight-bolder btn-sm btn-light-success px-5">
      New campaign
    </router-link > -->
    <div
      class="w-auto d-flex align-items-center btn-lg px-2"
      style="margin-right: 30px"
    >
      <div class="d-flex flex-column text-right pr-3">
        <span
          class="text-dark-75 font-weight-bolder font-size-base d-md-inline"
          >{{ userdata.email }}</span
        >
        <span class="text-muted font-weight-bold font-size-base d-md-inline"
          >{{ click }} clicks today | {{ rate }}% cloak rate</span
        >
      </div>
    </div>
    <!--<router-link title="New campaign" to="/build/new" class="btn btn-icon btn-clean ml-3 h-40px w-40px flex-shrink-0">
      <v-icon>add_box</v-icon>
    </router-link >
  -->
    <!--  <KTQuickNotifications class="mr-4"/>-->
    <button
      class="btn btn-icon btn-clean ml-3 h-40px w-40px flex-shrink-0"
      @click="refresh"
      title="refresh"

    >
      <span class="svg-icon svg-icon-xl">
        <!--begin::Svg Icon-->
        <v-icon>refresh</v-icon>
        <!--end::Svg Icon-->
      </span>
    </button>

    <KTQuickPanel />

    <a
      class="btn btn-icon btn-clean ml-3 h-40px w-40px flex-shrink-0"
      target="_blank"
      href="https://docs.cloakone.com/"
      title="Doc"
    >
    <span class="svg-icon svg-icon-xl">
      <v-icon>quiz</v-icon>
    </span>

  </a>


  <!--  <router-link
      to="/docs"
      class="btn btn-icon btn-clean ml-3 h-40px w-40px flex-shrink-0"
      title="Doc"
    >
      <span class="svg-icon svg-icon-xl">
        <v-icon>quiz</v-icon>
      </span>
    </router-link>-->

    <button
      class="btn btn-icon btn-clean ml-3 h-40px w-40px flex-shrink-0"
      @click="logout"
      title="Logout"
      style="margin-right: 20px"
    >
      <span class="svg-icon svg-icon-xl">
        <!--begin::Svg Icon-->
        <v-icon style="color: #feaab2">logout</v-icon>
        <!--end::Svg Icon-->
      </span>
    </button>
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<style scoped>
.logout {
  color: red;
}
</style>

<script>
import { PURGE_AUTH } from "@/core/services/store/auth.module";
import { RESET_KEY } from "@/core/services/store/config.module";

import ApiService from "@/core/services/api.service";
import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
//import KTQuickNotifications from "@/view/layout/extras/offcanvas/QuickNotifications.vue";
import { mapGetters } from "vuex";

export default {
  name: "Topbar",
  components: {
    KTQuickPanel,
    //KTQuickNotifications
  },
  data: () => ({
    click: 0,
    rate: 0,
  }),
  created() {
    this.getClickStats();
  },
  computed: {
    ...mapGetters(["userdata"]),
  },
  methods: {
    logout() {
      this.$store.commit(PURGE_AUTH);
      this.$router.push({ name: "login" });
    },

    refresh() {
      this.$store.commit(RESET_KEY);
    },

    docs() {
      this.$router.push({ name: "docs" });
    },

    async getClickStats() {
      if (this.userdata.email) {
        const data = await ApiService.get(`/stats/today`).then(
          (res) => res.data
        );

        if (data && data.length && data[0]) {
          this.click = data[0].click || 0;
          this.rate = (data[0].cloak_rate * 100 || 0).toFixed(1);
        }
      }
    },
  },
};
</script>
