<template>
  <div class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid wrapper"
        >
          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
              style="max-width:2500px;"
            >
              <div class="d-lg-flex flex-row-fluid">
                <!-- begin:: Aside Left -->
                <KTAside></KTAside>
                <!-- end:: Aside Left -->
                <div class="content-wrapper flex-row-fluid">
                  <router-view :key="reloadKey" v-if="hasCurrentPlan && $route.name === 'dashboard'"/>
                  <router-view :key="$route.params.id" v-if="(hasCurrentPlan || $route.name === 'billing') && $route.name !== 'dashboard'"/>
                  <Billing v-if="!hasCurrentPlan && $route.name !== 'billing'" />
                  <!-- <div class="update-plan" v-if="!hasCurrentPlan && $route.name !== 'billing'">
                    <h3 class="update-plan-title">Please update your current plan for start working</h3>
                    <div class="update-plan-actions">
                      <router-link to="/billing" class="btn btn-lg btn-success mt-2">Update plan</router-link>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <KTFooter></KTFooter> -->
      </div>
    </div>

    <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
    <KTScrollTop></KTScrollTop>
    <intercom />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HtmlClass from "@/core/services/htmlclass.service";
import KTAside from "@/view/layout/aside/Aside.vue";
import Loader from "@/view/layout/content/Loader";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import intercom from "@/view/layout/chat/intercom";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile";

import Billing from "@/view/pages/Billing.vue";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    Loader,
    KTScrollTop,
    intercom,
    Billing
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "userdata",
      "reloadKey"
    ]),

    hasCurrentPlan() {
      return this.userdata && this.userdata.status !== 'unpaid' && !!this.userdata.plan;
    },

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    /**
     * Set the subheader display on dashboard page
     * @returns {boolean}
     */
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    }
  }
};
</script>

<style>
.v-application--wrap {
  min-height: auto !important;
}

.content.wrapper {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
}

.update-plan {
  text-align: center;
  margin-top: 150px;
}
</style>
