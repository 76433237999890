<template>
  <div>
    <a
      href="#"
      class="btn btn-icon btn-clean btn-lg mb-1 ml-3 position-relative"
      id="kt_quick_panel_toggle"
      title="Profile Settings"
    >
      <span class="svg-icon svg-icon-xl">
        <!--begin::Svg Icon-->
        <inline-svg src="/media/svg/icons/General/User.svg" />
        <!--end::Svg Icon-->
      </span>
    </a>

    <!-- begin::Quick Panel -->
    <div
      id="kt_quick_panel"
      ref="kt_quick_panel"
      class="offcanvas offcanvas-right pt-5 pb-10"
      style="overflow: hidden;"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
      >
        <ul
          class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
          role="tablist"
        >
          <!-- <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="true"
            >
              Overview
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              Notifications
            </a>
          </li> -->
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              v-on:click="setActiveTab"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              Account
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-tab="1"
              v-on:click="setActiveTab"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              Settings
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-tab="2"
              data-toggle="tab"
              v-on:click="setActiveTab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              Billing
            </a>
          </li>
        <!--  <li class="nav-item">
            <a
              class="nav-link"
              data-tab="3"
              data-toggle="tab"
              v-on:click="setActiveTab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              Notification
            </a>
          </li>-->
        </ul>
        <div class="offcanvas-close mt-n1 pr-5">
          <a
            href="#"
            class="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_panel_close"
          >
            <i class="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
      </div>
      <!--end::Header-->

      <div class="offcanvas-content px-10">
        <div class="tab-content">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <!-- <b-tab active>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_logs">
                <br>
                  <div class="row gutter-b">
                    <div class="col-6">
                      <router-link
                        to="/docs"
                        class="btn btn-block btn-light btn-hover-success text-dark-50 text-center py-10 px-5"
                      >
                        <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                          <inline-svg src="/media/svg/icons/Code/Info-Circle.svg" />
                        </span>
                        <span class="d-block font-weight-bold font-size-h6 mt-2"
                          >Docs</span
                        >
                      </router-link>
                    </div>
                    <div class="col-6">
                      <router-link
                        to="/login"
                        class="btn btn-block btn-light btn-hover-danger text-dark-50 text-center py-10 px-5"
                      >
                        <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                          <inline-svg src="/media/svg/icons/Navigation/Sign-Out.svg" />
                        </span>
                        <span class="d-block font-weight-bold font-size-h6 mt-2"
                          >Logout</span
                        >
                      </router-link>
                    </div>
                  </div>

                </div>
            </b-tab> -->

            <!-- <b-tab>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_notifications">
                <div class="navi navi-icon-circle navi-spacer-x-0">
                  <template v-for="(item, i) in list">
                    <a href="#" class="navi-item" v-bind:key="i">
                      <div class="navi-link rounded">
                        <div class="symbol symbol-50 mr-3">
                          <div class="symbol-label">
                            <i class="icon-lg" v-bind:class="item.icon" />
                          </div>
                        </div>
                        <div class="navi-text">
                          <div class="font-weight-bold font-size-lg">
                            {{ item.title }}
                          </div>
                          <div class="text-muted">
                            {{ item.text }}
                          </div>
                        </div>
                      </div>
                    </a>
                  </template>
                </div>
              </div>
            </b-tab> -->

            <b-tab active>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_settings">
                <form class="form" @submit="submit($event, true)">
                  <br>
                  <h3 class="card-label font-weight-bolder text-dark"> Account Information </h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1">Change your personal data</span>
                  <div class="pt-3">
                    <div class="form-group row mb-1">
                      <label class="col-xl-12 col-lg-12 col-form-label">Email Adress</label>
                       <div class="col-lg-12 col-xl-12">
                          <div class="input-group input-group-lg input-group-solid">
                             <div class="input-group-prepend"><span class="input-group-text"><i class="la la-at"></i></span></div>
                             <input disabled type="text" v-model="userdata.email" placeholder="Name" class="form-control form-control-lg form-control-solid">
                          </div>
                       </div>
                    </div>
                    <div class="form-group row mb-1">
                      <label class="col-xl-12 col-lg-12 col-form-label">Telegram</label>
                       <div class="col-lg-12 col-xl-12">
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="flaticon2-telegram-logo"></i></span></div>
                            <input type="text" v-model="telegram" placeholder="Telegram" class="form-control form-control-lg form-control-solid">
                          </div>
                       </div>
                    </div>
                    <div class="form-group row mb-1">
                      <label class="col-xl-12 col-lg-12 col-form-label">Referral</label>
                       <div class="col-lg-12 col-xl-12">
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="flaticon2-world"></i></span></div>
                            <input type="text" v-model="referral" placeholder="Referral" class="form-control form-control-lg form-control-solid">
                          </div>
                       </div>
                    </div>
                  </div>

                  <button
                    type="submit" class="btn btn-lg btn-success w-100 mr-12 mt-5"
                  >
                    <span class="d-flex align-items-center justify-content-center" v-if="showSuccessText"><v-icon>done_outline</v-icon> Change saved</span>
                    <span v-else>Save changes</span>
                  </button>
                </form>

                <div class="separator separator-dashed my-5"></div>

                <span class="text-muted font-weight-bold font-size-sm mt-1">Change Password</span>

                <div class="pt-3">
                  <div class="form-group row mb-1">
                    <label class="col-xl-12 col-lg-12 col-form-label">Old Password</label>
                      <div class="col-lg-12 col-xl-12">
                        <div class="input-group input-group-lg input-group-solid">
                          <div class="input-group-prepend"><span class="input-group-text"><i class="flaticon-lock"></i></span></div>
                          <input type="password" v-model="oldPassword" placeholder="Old Password" class="form-control form-control-lg form-control-solid">
                        </div>
                      </div>
                  </div>
                  <div class="form-group row mb-1">
                    <label class="col-xl-12 col-lg-12 col-form-label">New Password</label>
                      <div class="col-lg-12 col-xl-12">
                        <div class="input-group input-group-lg input-group-solid">
                          <div class="input-group-prepend"><span class="input-group-text"><i class="flaticon-lock"></i></span></div>
                          <input type="password" v-model="newPassword" placeholder="New Password" class="form-control form-control-lg form-control-solid">
                        </div>
                      </div>
                  </div>
                </div>

                <button
                  class="btn btn-lg btn-success w-100 mr-12 mt-5"
                  @click="changePassword"
                  :disabled="!oldPassword || !newPassword"
                >
                  <span class="d-flex align-items-center justify-content-center" v-if="showSuccessPasswordText"><v-icon>done_outline</v-icon>Password saved</span>
                  <span v-else>Save password</span>
                </button>


              </div>
            </b-tab>

            <b-tab>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_settings">
                <form class="form" @submit="submit">
                  <!--begin::Section-->
                  <br>

                  <h3 class="card-label font-weight-bolder text-dark"> App settings </h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1">Сustomize your application</span>


                  <div>
                    <div class="form-group row">
                       <label class="col-xl-12 col-lg-12 col-form-label">Secret key</label>
                       <div class="col-xl-12">
                          <div class="input-group input-group-lg input-group-solid">
                            <input type="text" v-model="secretkey" placeholder="mysecretkey" class="form-control form-control-lg form-control-solid">
                          </div>
                          <span class="form-text text-muted">Use your secret key to debug campaign and access money page <a href="https://docs.cloakone.com" target="_blank" class="kt-link">Learn more</a>.</span>
                       </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-xl-12 col-lg-12 col-form-label">API Domain</label>
                       <div class="col-xl-12">
                          <div class="input-group input-group-lg input-group-solid">
                            <input type="text" v-model="api" placeholder="API" class="form-control form-control-lg form-control-solid">
                          </div>
                          <span class="form-text text-muted">Custom API domain <a href="https://docs.cloakone.com" target="_blank" class="kt-link">Learn more</a>.</span>
                       </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-xl-12 col-lg-12 col-form-label">API Key</label>
                       <div class="col-xl-12">
                          <div class="input-group input-group-lg input-group-solid">
                            <input type="text" v-model="userdata.apikey" disabled placeholder="API" class="form-control form-control-lg form-control-solid">
                          </div>
                          <span class="form-text text-muted">User API Key <a href="https://docs.cloakone.com" target="_blank" class="kt-link">Learn more</a>.</span>
                       </div>
                    </div>

                  </div>

                  <div class="separator separator-dashed my-10"></div>

                  <button
                    type="submit" class="btn btn-lg btn-success w-100 mr-12"
                  >
                    <span class="d-flex align-items-center justify-content-center" v-if="showSuccessText"><v-icon>done_outline</v-icon> Change saved</span>
                    <span v-else>Save changes</span>
                  </button>



                  <!--end::Section-->



                </form>
              </div>
            </b-tab>
            <b-tab>

              <br>

              <h3 class="card-label font-weight-bolder text-dark">Billing Settings</h3>
              <span class="text-muted font-weight-bold font-size-sm mt-1 mb-6 d-block">
                View the number of clicks available to you
              </span>

              <h6 class="font-weight-bold mt-1 mb-2" style="font-size: 14px;">
                Click paid: {{userdata.clicks_paid || 0}}
              </h6>

              <h6 class="font-weight-bold mt-1 mb-2" style="font-size: 14px;">
                Click used: {{userdata.clicks_used || 0}}
              </h6>

              <h6 class="font-weight-bold mt-1 mb-6" style="font-size: 14px;">
                Click available: {{(userdata.clicks_paid || 0) - (userdata.clicks_used || 0)}}
              </h6>

              <b-alert show variant="light" v-if="estimatedDays"> <v-icon>info</v-icon>  Based on your traffic the last 7 days, you will run out of click in {{estimatedDays}} days </b-alert>

              <router-link to="/billing" @click.native="closeOffcanvas" class="btn btn-lg btn-success mt-4"  style="width:100%">Buy clicks</router-link>
            </b-tab>
            <b-tab>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_logs">
                <form class="form" @submit="submit">
                  <br>

                  <h3 class="card-label font-weight-bolder text-dark"> Email notification </h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1">Events to get notified on</span>

                  <div class="mt-7">
                    <div class="form-group row">
                      <div class="col-lg-12 col-xl-12">
                        <div class="checkbox-list">
                          <label class="checkbox"><input v-model="notification" value="daily report" type="checkbox" checked="checked" /><span></span> Daily report </label>
                          <label class="checkbox"><input v-model="notification" value="campaign down" type="checkbox" /><span></span> Campaign is down </label>
                          <label class="checkbox"><input v-model="notification" value="weekly report" type="checkbox" /><span></span> Weekly report </label>
                          <label class="checkbox"><input v-model="notification" value="monthly report" type="checkbox" /><span></span> Monthly report </label>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="separator separator-dashed my-10"></div>

                   <button
                      type="submit" class="btn btn-lg btn-success w-100 mr-12"
                    >
                      <span class="d-flex align-items-center justify-content-center" v-if="showSuccessText"><v-icon>done_outline</v-icon> Change saved</span>
                      <span v-else>Save changes</span>
                    </button>
                </form>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <!-- end::Quick Panel -->
  </div>
</template>

<script>
import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "KTQuickPanel",
  data() {
    return {
      list: [],
      eventsToday : 0,
      tabIndex: 0,
      email : false,
      notification : [],
      api: '',
      secretkey: '',
      disabled: false,
      showSuccessText: false,
      showSuccessPasswordText: false,
      oldPassword: null,
      newPassword: null,
      telegram: null,
      referral: null,
      clickLastWeek: 0
    };
  },
  created() {
    this.getWeekStats()
  },
  mounted() {
    // Init Quick Offcanvas Panel
    KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);

    // let self = this
    // setTimeout(function(){
    //     self.getList()
    //   },
    // 3000);

    this.api = this.userdata.api || '';
    this.secretkey = this.userdata.secretkey || '';
    this.notification = this.userdata.notification || [];
    this.telegram = this.userdata.telegram || null;
    this.referral = this.userdata.referral || null;
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },

    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickPanel.getElement()).hide();
    },

    async submit(e) {
      e.preventDefault();

      if (this.disabled) return;

      this.disabled = true;

      let data = {
        secretkey: this.secretkey,
        api: this.api,
        notification: this.notification,
        telegram: this.telegram,
        referral: this.referral
      }

      await ApiService.put(`auth/user`, data).then(res => {
        if (res.status == 200) {
          this.userdata.secretkey = this.secretkey;
          this.userdata.api = this.api;

          this.showSuccessText = true;

          setTimeout(() => {
            this.showSuccessText = false;
            this.disabled = false;
          }, 3000)
        }
      }).catch(() => {
        this.disabled = false;
      })
    },

    async getWeekStats() {
      const data = await ApiService.get(`/stats/week`)
        .then(res => res.data)
        .catch(() => null)

      if (data) {
        const total = data.find(it => it.DAY === null);

        this.clickLastWeek = total && total.click || 0;
      }
    },

    async changePassword(e) {
      e.preventDefault();

      if (this.disabled) return;

      this.disabled = true;

      let data = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      }

      await ApiService.put(`auth/user/password`, data).then(res => {
        if (res.status == 200) {
          this.showSuccessPasswordText = true;

          setTimeout(() => {
            this.showSuccessPasswordText = false;
            this.disabled = false;
          }, 3000)
        }
      }).catch(err => {
        if (err.response && err.response.data) {
          Swal.fire({
            title: "Error",
            text: err.response.data.errors && err.response.data.errors[0] || 'Unknown error',
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            timer: 1000
          });
        }
        this.disabled = false;
      })
    },

    async getList(){
      if(this.userdata.email){
        let response = await ApiService.get(`notification`,`?email=${this.userdata.email}`)

        this.list = response.data

        for ( let item of this.list ){
          item.title = item.name
          item.text = `${item.text} | ${item.date}`
          item.icon = "flaticon-bell text-success"

          if (item.name == "page is online"){
            item.icon = "flaticon-bell text-success"
          }

          if (item.name == "page is offline"){
            item.icon = "flaticon-bell text-danger"
          }

          if (item.name == "daily stats"){
            item.icon = "flaticon-safe-shield-protection text-success"
          }

          if(item.today ){
            this.eventsToday += item.today
          }
        }

        this.eventsToday = this.list.length
      }
    },
  },
  computed: {
    ...mapGetters(["userdata"]),
    estimatedDays() {
      if (!this.userdata) return null;

      const available = (this.userdata.clicks_paid || 0) - (this.userdata.clicks_used || 0);

      return this.clickLastWeek === 0 || !available ? null : parseInt(available / this.clickLastWeek)
    }
  }
};
</script>

<style>
.tab-content ul[role="tablist"] {
  display: none;
}
</style>
