<template>
<div></div>
</template>

<script>

//import crypto from "crypto";
import { mapGetters } from "vuex";


export default {


  components: {
    //crypto,
  },


  data() {
    return {
    };
  },



  methods:{

    setUserInfo(data) {
      if (!data || !data.fields) {
        return;
      }

      const { email, newsletter, interest, status, plan, preview, download } = data.fields;

      this.userInfo = {
        firstname: data.fields['first name'],
        lastname: data.fields['last name'],
        email, interest, preview, download, newsletter, status
      }

      this.intercom()

    },


    intercom(){

      console.log(`intercom ${this.userInfo.email}`)

      //const signer = crypto.createHmac('sha256', 'J7pyzYSyHZotMgBHq8ZFjeEHYAfmywXAYy6LDTSp');
      //const sha256 = signer.update(this.userdata.uid).digest('hex');

      window.Intercom('boot', {

        app_id: '',

        name: this.userInfo.firstname + " " + this.userInfo.lastname,
        email: this.userInfo.email,

        "first_name": this.userInfo.firstname,
        "last_name": this.userInfo.lastname,

        plan : this.userInfo.plan,
        status : this.userInfo.status,

        "download": 1,
        "preview": 1,

        "newsletter" : this.userInfo.newsletter,
        "interest": this.userInfo.interest,

        "data" : JSON.stringify(this.userInfo),

      });


    },

  },

  computed: {
    ...mapGetters([
      "userdata"
    ]),
  },

  watch: {
    userdata: function(val) {
      this.setUserInfo(val)
    }
  },




  mounted() {

    this.setUserInfo(this.userdata)

  }









};


</script>
