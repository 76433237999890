<template>
  <div
    id="kt_header_mobile"
    ref="kt_header_mobile"
    class="header-mobile header-mobile-fixed"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <div class="header-logo">
      <router-link to="/">
        <img
          alt="Logo"
          :src="layoutConfig('self.logo')"
          class="logo-sticky max-h-35px"
        />
      </router-link>
    </div>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <button
        class="btn rounded-0 burger-icon burger-icon-left mr-5 ml-3"
        id="kt_aside_mobile_toggle"
        ref="kt_aside_mobile_toggle"
      >
        <span></span>
      </button>

      <a
        href="#"
        class="btn btn-icon btn-clean btn-lg mb-1 position-relative kt_quick_panel_toggle"
        id="kt_quick_panel_toggle_mobile"
        title="Profile Settings"
      >
        <span class="svg-icon svg-icon-xl">
          <inline-svg src="/media/svg/icons/General/User.svg" />
        </span>
      </a>

      <router-link to="/docs"
        class="btn btn-icon btn-clean ml-3 h-40px w-40px flex-shrink-0"
        title="Doc"
      >
        <span class="svg-icon svg-icon-xl">
          <v-icon>quiz</v-icon>
        </span>
      </router-link>

      <button
        class="btn btn-icon btn-clean ml-3 h-40px w-40px flex-shrink-0"
        @click="logout"
        title="Logout"
        style="margin-right:20px"

      >
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon-->
          <v-icon style="color:#feaab2">logout</v-icon>
          <!--end::Svg Icon-->
        </span>
      </button>
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { PURGE_AUTH } from "@/core/services/store/auth.module";
import { mapGetters } from 'vuex';
export default {
  name: "KTHeaderMobile",
  components: {
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),
    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo");
    },
    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return this.layoutConfig("aside.self.display");
    }
  },
  methods: {
    logout() {
      this.$store.commit(PURGE_AUTH)
      this.$router.push({ name: 'login' })
    },
  }
};
</script>

<style scoped>
.header-mobile {
  margin-bottom: 20px;
}
</style>