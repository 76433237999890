<template>
  <div>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <a
          href="#"
          v-bind="attrs" v-on="on"
          class="btn btn-icon btn-clean btn-lg mb-1"
          id="kt_quick_actions_toggle"
          data-toggle="tooltip"
          data-placement="right"
          data-container="body"
          data-boundary="window"
          style=""
          data-original-title="Stats"
        >
          <span class="svg-icon svg-icon-xxl">
            <!--begin::Svg Icon-->
            <inline-svg src="/media/svg/icons/Media/Equalizer.svg" />
            <!--end::Svg Icon-->
          </span>
        </a>
      </template>
      <span>Stats</span>
    </v-tooltip>

    <!-- begin::Quick Panel -->
    <div
      id="kt_quick_actions"
      ref="kt_quick_actions"
      class="offcanvas offcanvas-left p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-10"
        kt-hidden-height="56"
        style=""
      >
        <h3 class="font-weight-bold m-0">
          Stats Report
          <small class="text-muted font-size-sm ml-2"> </small>
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_actions_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->
      <!--begin::Content-->
      <div
        class="offcanvas-content pr-5 mr-n5 scroll ps"
        style="height: 804px; overflow: hidden;"
      >

      <div class="row gutter-b">
        <div class="col-12">
          <router-link
            to="/stats/overview"
            class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
              <!--begin::Svg Icon-->
              <inline-svg src="/media/svg/icons/Media/Equalizer.svg" />
              <!--end::Svg Icon-->
            </span>
            <span class="d-block font-weight-bold font-size-h6 mt-2"
              >Overview</span
            >
          </router-link>
        </div>




          </div>
        <!--end::Content-->
      </div>
      <!-- end::Quick Panel -->
    </div>
    <!-- end::Quick Panel -->
  </div>
</template>

<script>
import KTLayoutQuickActions from "@/assets/js/layout/extended/quick-actions.js";

export default {
  name: "KTQuickActions",
  data() {
    return {};
  },
  mounted() {
    // Init Quick Actions Offcanvas Panel
    KTLayoutQuickActions.init(this.$refs["kt_quick_actions"]);
  }
};
</script>
